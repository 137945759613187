<template>
  <GenerateReportFBAPageTableAdmin
      v-if="isAdmin"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :navTabs="navTabs"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @changeTab="changeTab"
      @reload="reload"
  />
</template>

<script>
  import GenerateReportFBAPageTableAdmin from "./GenerateReportFBAPageTableAdmin/GenerateReportFBAPageTableAdmin";
  import { routeFilter } from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "GenerateReportFBAPageTable",
    components: {
      GenerateReportFBAPageTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getGenerateReportForPage,
        page: 1,
        onePage: 1,
        filterType: '',

        filterUserId: '',
        filterUserName: '',
        filterCreatedAt: [],
        filterExcludeUser: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          completed: {
            active: true,
            label: this.$t('generateReport_completed.localization_value.value'),
            name: 'completed',
            id: false,
          },
          missing: {
            active: false,
            label: this.$t('generateReport_missing.localization_value.value'),
            name: 'missing',
            id: false,
          },
        }
      }
    },
    mounted() {
      this.filter();
    },
    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          createdAt: this.$route.query.createdAt,
          excludeUser: this.$route.query.excludeUser,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this);

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        // let urlTimeAM = this.generateFilterUrl(
        //     generateMainFilter.page,
        //     generateMainFilter.forPage,
        //     'startTime'
        // )
        //
        // let urlTimeMD = this.generateFilterUrl(
        //     generateMainFilter.page,
        //     generateMainFilter.forPage,
        //     'middleTime'
        // )

        let url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage,
        )

        if (next)
          this.$store.commit('setNextGenerateReportPage', true)

        // // to 12:00
        // this.$store.dispatch('fetchGenerateReportFBA', urlTimePM).then(() => {
        //   this.$store.commit('setGlobalUpdateTable', false)
        // });
        // // after 12:00 before 14:00
        // this.$store.dispatch('fetchGenerateReportFBAMD', urlTimeMD).then(() => {
        //   this.$store.commit('setGlobalUpdateTable', false)
        // });
        // // after 14:00
        this.$store.dispatch('fetchGenerateReportFBA', url).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)
        });


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'userId',
              // excludeUser: 'ExcludeUser',
            },
        )

        if (this.filterCreatedAt.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedAt, 'YYYY-MM-DD')
          myQuery.whereIn('between_created_at', date)
        }

        let url = this.generateGetParamsFromQuery(myQuery, forPage, page)
        // let timeFilter = time === 'startTime' ? '&start_time_interval=00:00:00&end_time_interval=12:00:00' :
        //     time === 'middleTime' ?
        //         '&start_time_interval=12:00:00&end_time_interval=14:00:00' :
        //         '&start_time_interval=14:00:00&end_time_interval=23:59:59'
        // url += timeFilter

        if(this.navTabs.completed.active){
          url += '&type=complete'
        }
        if(this.navTabs.missing.active){
          url += '&type=missing'
        }

        return url
      },
    }
  }
</script>

<style scoped>

</style>
