<template>
  <div class="fragment">

<!--    <SearchEngine/>-->

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'generateReport_completed',
          'generateReport_missing',
        ])"></div>

      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>
    <div class="light-bg-block">
      <div class="btn-right-block">
        <span @click="printTable">
         <MainButton
             :value="$t('generateReport_print.localization_value.value')"
             class="print-btn-head"
             :ico="true"
         >
          <template slot="ico">
            <PrintWhiteIcon/>
          </template>
        </MainButton>
        </span>
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import PrintWhiteIcon from "../../../../../../../../public/img/common/print-white-icon.svg?inline";


  export default {
    name: "GenerateReportFBAPage",
    components: {
      ToggleFilterButton,
      MainButton,
      // SearchEngine,
      PrintWhiteIcon,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        printFile: null,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },
      printTable() {
        window.print()
      },
    },
  }
</script>

<style media="print">

  .print-btn-head{
    max-width: 167px;
  }

  @page { size: landscape; }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
    }
    body .noPrintable {
      display: none;
    }
    .default-layout__page-wrap{
      padding-left: 0 !important;
    }
    .manager-btn{
      display: none !important;
    }

    .tooltip-helper.max-width-helper{
      width: 350px !important;
    }
    .tooltip-helper-full{
      white-space: initial !important;
      max-width: 300px !important;
    }

    .header__inner{
      display: none !important;
    }

    .default-layout__page-content{
      margin-top: 30px;
    }
  }

</style>

