<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">
      <div class="filter-wrapper-scoped">
        <div class="custom-row">
          <div class="table-filter__item"
               v-if="isAdmin"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

            <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :userEmail="true"
                :typeSelect="'users'"
            />
          </div>

          <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 mb-3">
            <DatePickerDefault
                :label="'Period'"
                v-model="createdAt"
            >
              <template slot="body">
                <date-picker
                    v-model="createdAt"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>


        </div>
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";

  export default {
    name: "GenerateReportFBAPage",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        createdAt: this.filterGetParams.createdAt ? this.generateFilterDate(this.filterGetParams.createdAt, 'MM/DD/YY'): '',
        
        options: [],

        filterCounts: [
          'userId',
          'userName',
          'createdAt',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.createdAt = newVal.createdAt ? this.generateFilterDate(newVal.createdAt, 'MM/DD/YY') : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdAt = this.changeDateParams(this, 'createdAt')

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>
  .filter-wrapper-scoped{
    max-width: 915px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }
</style>
