<template>
  <div class="fragment">

    <template v-if="!$store.getters.getGenerateReportLoading">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_Before',
            'generateReport_OredersCount',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_Before.localization_value.value') }}
            </div>
            <div class="before-time-block__orders">
              {{$store.getters.getGenerateReportAM.length}}
<!--              {{ getCountOrders($store.getters.getGenerateReportAM) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('generateReport_User.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
            <th width="100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(report, index) of $store.getters.getGenerateReportAM" :key="index">

            <td>
<!--              <TableUserColumn-->
<!--                  :item="{user: {-->
<!--                    id: report.user.id,-->
<!--                    show_n_mark: report.user.show_n_mark,-->
<!--                    user_settings: report.user.user_settings,-->
<!--                    user_personal_contact :{user_full_name: report.user.user_full_name},-->
<!--                  }}"-->
<!--              />-->
              <TableUserColumn
                  :item="report"/>
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id">
                {{ report.order.id }}
              </router-link>
              <br>
              {{ report.order.created_at | moment("DD MMM, YYYY") }}
              <br>
              {{ report.order.created_at | moment("HH:mm:ss") }}
            </td>
            <td>
              <div class="table-row">
                <TooltipTextHelper :fullTextWidth="530">
                  <template slot="text">
                    <router-link class="table-link"
                                 target="_blank"
                                 :to="$store.getters.GET_PATHS.products + '/' + report.product.id">
                      {{ report.product.combo_name }}
                    </router-link>
                  </template>
                  <template slot="title">
                    {{ report.product.combo_name }}
                  </template>
                </TooltipTextHelper>
              </div>
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
              <br>
              <template v-if="report.product.last_inbound">
                {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
              </template>
            </td>
            <td>
              {{ report.product.order_item_quantity }}
            </td>
            <td>
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </td>

            <td width="100%"></td>
            <td align="right">
              <LinkButton
                  class="mr-3 noPrintable"
                  v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                  :link="$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id"
                  :value="$t('generateReport_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
              />
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(report, index) in $store.getters.getGenerateReportAM"
                 :key="index">
              <GenerateReportFBAPageTableMobile
                  :report="report"
              />
            </div>
          </div>
        </div>
        <!--      <div class="table-bottom-btn">-->
        <!--        <div class="table-bottom-btn__inner">-->
        <!--          <ShowMore :count="'25'" @click.native="$emit('showMore')"/>-->
        <!--          <ExportBtn class="table-bottom-btn__right"/>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>

      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'generateReport_BetweenMiddle',
          'generateReport_OredersCount',
        ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_BetweenMiddle.localization_value.value') }}
            </div>
            <div class="before-time-block__orders">
              {{$store.getters.getGenerateReportMD.length}}
<!--                {{ getCountOrders($store.getters.getGenerateReportMD) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th>{{ $t('generateReport_User.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
              <th width="100%"></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(report, index) of $store.getters.getGenerateReportMD" :key="index">

              <td>
                <TableUserColumn
                    :item="report"/>
<!--                <TableUserColumn-->
<!--                    :item="{user: {-->
<!--                    id: report.user.id,-->
<!--                    show_n_mark: report.user.show_n_mark,-->
<!--                    user_settings: report.user.user_settings,-->
<!--                    user_personal_contact :{user_full_name: report.user.user_full_name},-->
<!--                  }}"-->
<!--                />-->
              </td>
              <td>
                <router-link class="table-link"
                             target="_blank"
                             :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id">
                  {{ report.order.id }}
                </router-link>
                <br>
                {{ report.order.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.order.created_at | moment("HH:mm:ss") }}
              </td>
              <td>
                <div class="table-row">
                  <TooltipTextHelper :fullTextWidth="300">
                    <template slot="text">
                      <router-link class="table-link"
                                   target="_blank"
                                   :to="$store.getters.GET_PATHS.products + '/' + report.product.id">
                        {{ report.product.combo_name }}
                      </router-link>
                    </template>
                    <template slot="title">
                      {{ report.product.combo_name }}
                    </template>
                  </TooltipTextHelper>
                </div>
              </td>
              <td>
                <router-link class="table-link"
                             target="_blank"
                             v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                             :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                  <ValueHelper
                      :value="report"
                      :deep="'product.last_inbound.order_model_id'"
                  />
                </router-link>
                <br>
                <template v-if="report.product.last_inbound">
                  {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                  <br>
                  {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
                </template>
              </td>
              <td>
                {{ report.product.order_item_quantity }}
              </td>
              <td>
                <template v-for="comboName of report.product.items_in_storage">
                  <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                    {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                  </div>
                </template>
              </td>

              <td width="100%"></td>
              <td align="right">
                <LinkButton
                    class="mr-3 noPrintable"
                    v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                    :link="$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id"
                    :value="$t('generateReport_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                />
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-if="isMobileFunc()">
            <div class="table-card__list pt-0">
              <div class="table-card__item"
                   v-for="(report, index) in $store.getters.getGenerateReportMD"
                   :key="index">
                <GenerateReportFBAPageTableMobile
                    :report="report"
                />
              </div>
            </div>
          </div>
          <!--      <div class="table-bottom-btn">-->
          <!--        <div class="table-bottom-btn__inner">-->
          <!--          <ShowMore :count="'25'" @click.native="$emit('showMore')"/>-->
          <!--          <ExportBtn class="table-bottom-btn__right"/>-->
          <!--        </div>-->
          <!--      </div>-->
        </div>

      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_After',
            'generateReport_OredersCount',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_After.localization_value.value') }}
            </div>
            <div class="before-time-block__orders">
              {{$store.getters.getGenerateReport.length}}
<!--              {{ getCountOrders($store.getters.getGenerateReport) }}-->
              {{ $t('generateReport_OredersCount.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_Quality',
            'generateReport_Cell',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('generateReport_User.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
            <th width="100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(report, index) of $store.getters.getGenerateReport" :key="index">

            <td>
              <TableUserColumn
                  :item="report"
              />
<!--              :item="{user: {-->
<!--              id: report.user.id,-->
<!--              show_n_mark: report.user.show_n_mark,-->
<!--              user_settings: report.user.user_settings,-->
<!--              user_personal_contact :{user_full_name: report.user.user_full_name},-->
<!--              }}"-->
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id">
                {{ report.order.id }}
              </router-link>
              <br>
              {{ report.order.created_at | moment("DD MMM, YYYY") }}
              <br>
              {{ report.order.created_at | moment("HH:mm:ss") }}
            </td>
            <td>
              <div class="table-row">
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <router-link class="table-link"
                                 target="_blank"
                                 :to="$store.getters.GET_PATHS.products + '/' + report.product.id">
                      {{ report.product.combo_name }}
                    </router-link>
                  </template>
                  <template slot="title">
                    {{ report.product.combo_name }}
                  </template>
                </TooltipTextHelper>
              </div>
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
              <br>
              <template v-if="report.product.last_inbound">
                {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
              </template>
            </td>
            <td>
              {{ report.product.order_item_quantity }}
            </td>
            <td>
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </td>

            <td width="100%"></td>
            <td align="right">
              <LinkButton
                  class="mr-3 noPrintable"
                  v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                  :link="$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id"
                  :value="$t('generateReport_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
              />
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(report, index) in $store.getters.getGenerateReport"
                 :key="index">
              <GenerateReportFBAPageTableMobile
                  :report="report"
              />
            </div>
          </div>
        </div>
      </div>

    </template>


    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
      </div>
    </div>


  </div>
</template>

<script>
// import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import GenerateReportFBAPageTableMobile
  from "@/components/modules/GenerateReportFBAPageModule/components/GenerateReportFBAPageTable/GenerateReportFBAPageTableAdmin/GenerateReportFBAPageTable/GenerateReportFBAPageTableMobile/GenerateReportFBAPageTableMobile";
import {FBM_ORDER_TYPES} from "@/staticData/staticVariables";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
// import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
// import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";


export default {
  name: "GenerateReportFBAPageTable",

  components: {
    TableUserColumn,
    GenerateReportFBAPageTableMobile,
    ValueHelper,
    TooltipTextHelper,
    // TooltipBtn,
    // MainButton,
    // DefaultInput,
    LinkButton,
    // ProductsToWarehouse,
  },

  mixins: [mixinDetictingMobile, ordersInboundMixin],

  data() {
    return {
      orderId: '',
      showFilter: false,
      isMobile: false,

      showContent: false,

    }
  },

  methods: {
    getCountOrders(orders) {
      let array = []
      orders.map(order => {
        if(!this._.find(array, {id: order.order.id})){
          array.push(order.order)
        }
      })
      return array.length
    },

    getFBMType(type) {
      let actualType = ''

      switch (type) {
        case 'consolidation':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
          break
        case 'sklad_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
          break
        case 'user_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
          break
      }

      return actualType
    },

    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    cantDeleteProduct() {
      let text = {
        title: 'Ooops',
        txt: 'Sorry, you cannot uninstall this product',
        no: 'Ok'
      }

      let confirm = () => {
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    removeProduct() {
      let text = {
        title: 'Delete Product?',
        txt: 'Are you sure you want to Delete Product?',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        // this.$store.dispatch('deleteShopPermission', id).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     let shops = this.$store.getters.getShops
        //     shops.map((item, index) => {
        //       if(item.id === id) {
        //         shops[index].shop_oauth_param.key_and_token_exist = 0
        //       }
        //     })
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('success', 'Произошла ошибка')
        //     }, 200)
        //   }
        // })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    }
  }

}

</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table-manage-list {
  width: 100%;

  .manager-btn {
    max-width: 100%;
  }
}


@media (max-width: 1900px) {
  .comment-row {
    width: 200px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 150px;
  }
}

//.site-table thead th:first-child,
//.site-table tbody td:first-child{
//  padding-right: 0;
//}

//.site-table thead th:nth-child(2),
//.site-table tbody td:nth-child(2){
//  padding-left: 0;
//}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}


@media (min-width: 1300px) {
  .site-table thead th:nth-child(1) {
    min-width: 250px;
  }

  .site-table thead th:nth-child(2) {
    min-width: 100px;
  }

  .site-table thead th:nth-child(3) {
    min-width: 320px;
  }

  .site-table thead th:nth-child(4) {
    min-width: 101px;
  }

  .site-table thead th:nth-child(5) {
    min-width: 85px;
  }

  .site-table thead th:nth-child(6) {
    min-width: 95px;
  }
}

</style>
