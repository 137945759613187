var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(!_vm.$store.getters.getGenerateReportLoading)?[_c('div',{staticClass:"fragment",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_Before',
            'generateReport_OredersCount',
          ])}}}),_c('div',{staticClass:"before-time-block"},[_c('div',{staticClass:"before-time-block__item line"},[_c('div',{staticClass:"before-time-block__time"},[_vm._v(" "+_vm._s(_vm.$t('generateReport_Before.localization_value.value'))+" ")]),_c('div',{staticClass:"before-time-block__orders"},[_vm._v(" "+_vm._s(_vm.$store.getters.getGenerateReportAM.length)+" "),_vm._v(" "+_vm._s(_vm.$t('generateReport_OredersCount.localization_value.value'))+" ")])])])]),_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_Quality',
            'generateReport_Cell',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_User.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Order.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Name.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Inbound.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Quality.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Cell.localization_value.value')))]),_c('th',{attrs:{"width":"100%"}}),_c('th')])]),_c('tbody',_vm._l((_vm.$store.getters.getGenerateReportAM),function(report,index){return _c('tr',{key:index},[_c('td',[_c('TableUserColumn',{attrs:{"item":report}})],1),_c('td',[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id}},[_vm._v(" "+_vm._s(report.order.id)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"HH:mm:ss"))+" ")],1),_c('td',[_c('div',{staticClass:"table-row"},[_c('TooltipTextHelper',{attrs:{"fullTextWidth":530}},[_c('template',{slot:"text"},[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.products + '/' + report.product.id}},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],1),_c('template',{slot:"title"},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],2)],1)]),_c('td',[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    _vm.getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id}},[_c('ValueHelper',{attrs:{"value":report,"deep":'product.last_inbound.order_model_id'}})],1):_vm._e(),_c('br'),(report.product.last_inbound)?[_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"HH:mm:ss"))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(report.product.order_item_quantity)+" ")]),_c('td',[_vm._l((report.product.items_in_storage),function(comboName){return [(comboName.cell !== null && comboName.cell.combo_name !== null)?_c('div',{key:comboName.id},[_vm._v(" "+_vm._s(comboName.cell.combo_name)+" ("+_vm._s(comboName.quantity)+") ")]):_vm._e()]})],2),_c('td',{attrs:{"width":"100%"}}),_c('td',{attrs:{"align":"right"}},[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('LinkButton',{staticClass:"mr-3 noPrintable",attrs:{"link":_vm.$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id,"value":_vm.$t('generateReport_edit.localization_value.value'),"type":'edit',"target":'_blank'}}):_vm._e()],1)])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list pt-0"},_vm._l((_vm.$store.getters.getGenerateReportAM),function(report,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('GenerateReportFBAPageTableMobile',{attrs:{"report":report}})],1)}),0)]):_vm._e()]),_c('div',{staticClass:"fragment",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'generateReport_BetweenMiddle',
          'generateReport_OredersCount',
        ])}}}),_c('div',{staticClass:"before-time-block"},[_c('div',{staticClass:"before-time-block__item line"},[_c('div',{staticClass:"before-time-block__time"},[_vm._v(" "+_vm._s(_vm.$t('generateReport_BetweenMiddle.localization_value.value'))+" ")]),_c('div',{staticClass:"before-time-block__orders"},[_vm._v(" "+_vm._s(_vm.$store.getters.getGenerateReportMD.length)+" "),_vm._v(" "+_vm._s(_vm.$t('generateReport_OredersCount.localization_value.value'))+" ")])])])]),_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_User.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Order.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Name.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Inbound.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Quality.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Cell.localization_value.value')))]),_c('th',{attrs:{"width":"100%"}}),_c('th')])]),_c('tbody',_vm._l((_vm.$store.getters.getGenerateReportMD),function(report,index){return _c('tr',{key:index},[_c('td',[_c('TableUserColumn',{attrs:{"item":report}})],1),_c('td',[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id}},[_vm._v(" "+_vm._s(report.order.id)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"HH:mm:ss"))+" ")],1),_c('td',[_c('div',{staticClass:"table-row"},[_c('TooltipTextHelper',{attrs:{"fullTextWidth":300}},[_c('template',{slot:"text"},[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.products + '/' + report.product.id}},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],1),_c('template',{slot:"title"},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],2)],1)]),_c('td',[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    _vm.getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id}},[_c('ValueHelper',{attrs:{"value":report,"deep":'product.last_inbound.order_model_id'}})],1):_vm._e(),_c('br'),(report.product.last_inbound)?[_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"HH:mm:ss"))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(report.product.order_item_quantity)+" ")]),_c('td',[_vm._l((report.product.items_in_storage),function(comboName){return [(comboName.cell !== null && comboName.cell.combo_name !== null)?_c('div',{key:comboName.id},[_vm._v(" "+_vm._s(comboName.cell.combo_name)+" ("+_vm._s(comboName.quantity)+") ")]):_vm._e()]})],2),_c('td',{attrs:{"width":"100%"}}),_c('td',{attrs:{"align":"right"}},[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('LinkButton',{staticClass:"mr-3 noPrintable",attrs:{"link":_vm.$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id,"value":_vm.$t('generateReport_edit.localization_value.value'),"type":'edit',"target":'_blank'}}):_vm._e()],1)])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list pt-0"},_vm._l((_vm.$store.getters.getGenerateReportMD),function(report,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('GenerateReportFBAPageTableMobile',{attrs:{"report":report}})],1)}),0)]):_vm._e()]),_c('div',{staticClass:"fragment",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_After',
            'generateReport_OredersCount',
          ])}}}),_c('div',{staticClass:"before-time-block"},[_c('div',{staticClass:"before-time-block__item line"},[_c('div',{staticClass:"before-time-block__time"},[_vm._v(" "+_vm._s(_vm.$t('generateReport_After.localization_value.value'))+" ")]),_c('div',{staticClass:"before-time-block__orders"},[_vm._v(" "+_vm._s(_vm.$store.getters.getGenerateReport.length)+" "),_vm._v(" "+_vm._s(_vm.$t('generateReport_OredersCount.localization_value.value'))+" ")])])])]),_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_Quality',
            'generateReport_Cell',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_User.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Order.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Name.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Inbound.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Quality.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('generateReport_Cell.localization_value.value')))]),_c('th',{attrs:{"width":"100%"}}),_c('th')])]),_c('tbody',_vm._l((_vm.$store.getters.getGenerateReport),function(report,index){return _c('tr',{key:index},[_c('td',[_c('TableUserColumn',{attrs:{"item":report}})],1),_c('td',[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id}},[_vm._v(" "+_vm._s(report.order.id)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.order.created_at,"HH:mm:ss"))+" ")],1),_c('td',[_c('div',{staticClass:"table-row"},[_c('TooltipTextHelper',{attrs:{"fullTextWidth":300}},[_c('template',{slot:"text"},[_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.products + '/' + report.product.id}},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],1),_c('template',{slot:"title"},[_vm._v(" "+_vm._s(report.product.combo_name)+" ")])],2)],1)]),_c('td',[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('router-link',{staticClass:"table-link",attrs:{"target":"_blank","to":_vm.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    _vm.getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id}},[_c('ValueHelper',{attrs:{"value":report,"deep":'product.last_inbound.order_model_id'}})],1):_vm._e(),_c('br'),(report.product.last_inbound)?[_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"DD MMM, YYYY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(report.product.last_inbound.created_at,"HH:mm:ss"))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(report.product.order_item_quantity)+" ")]),_c('td',[_vm._l((report.product.items_in_storage),function(comboName){return [(comboName.cell !== null && comboName.cell.combo_name !== null)?_c('div',{key:comboName.id},[_vm._v(" "+_vm._s(comboName.cell.combo_name)+" ("+_vm._s(comboName.quantity)+") ")]):_vm._e()]})],2),_c('td',{attrs:{"width":"100%"}}),_c('td',{attrs:{"align":"right"}},[(report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0)?_c('LinkButton',{staticClass:"mr-3 noPrintable",attrs:{"link":_vm.$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id,"value":_vm.$t('generateReport_edit.localization_value.value'),"type":'edit',"target":'_blank'}}):_vm._e()],1)])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list pt-0"},_vm._l((_vm.$store.getters.getGenerateReport),function(report,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('GenerateReportFBAPageTableMobile',{attrs:{"report":report}})],1)}),0)]):_vm._e()])]:_vm._e(),_vm._m(0)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"})])
}]

export { render, staticRenderFns }