<template>
  <div class="table-card__item-content">
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          <router-link class="table-link"
                       target="_blank"
                       :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + report.order.id">
            {{ report.order.id }}
          </router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('generateReport_User.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-row d-flex flex-wrap">
                <!--                <StatusIcoBtn-->
                <!--                    class="mr-1 t-0"-->
                <!--                    :type="'delivered'"-->
                <!--                />-->
                <!--                <LabelCircle-->
                <!--                    class="oval mr-1"-->
                <!--                    :color="'blue'"-->
                <!--                    :value="'NEW'"-->
                <!--                />-->
                <div class="table-link btn-style mr-1 white-space-line">
<!--                  <TableUserColumn-->
<!--                      :item="{user: {-->
<!--                              id: report.user.id,-->
<!--                              show_n_mark: report.user.show_n_mark,-->
<!--                              user_settings: report.user.user_settings,-->
<!--                              user_personal_contact: {user_full_name: report.user.user_full_name},-->
<!--                            }}"-->
<!--                      :mobile="true"/>-->

                  <TableUserColumn
                      :item="report"
                      :mobile="true"/>
                </div>
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('generateReport_Name.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ report.product.combo_name }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Inbound.localization_value.value') }}
            </div>
            <div class="table-card__item-info direction-column">
              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Quality.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ report.product.order_item_quantity }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Cell.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="table-card__item-btn pl-2">
          <div class="table-card__item-btn-i">
            <LinkButton
                class="mr-3"
                v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                :link="$store.getters.GET_PATHS.ordersFBA + '/' + report.order.id"
                :value="$t('generateReport_edit.localization_value.value')"
                :type="'edit'"
                :target="'_blank'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{ $t('generateReport_showMore.localization_value.value') }}
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import {FBM_ORDER_TYPES} from "@/staticData/staticVariables";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";

export default {
  name: "GenerateReportFBAPageTableMobile",
  components: {TableUserColumn, LinkButton, ValueHelper},

  mixins: [ordersInboundMixin],

  props: {
    report: Object,
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    getFBMType(type) {
      let actualType = ''

      switch (type) {
        case 'consolidation':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
          break
        case 'sklad_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
          break
        case 'user_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
          break
      }

      return actualType
    },

  }

}
</script>

<style scoped>

</style>